import React, { useMemo } from 'react';
import { Routes, Route } from "react-router-dom";

import APICtx, { useApi } from '#api'
import Screen, { TplWSidebar as Tpl } from '#Template'
import {
	faFarm,
	faBuildingWheat,
	faPeopleArrows,
	faFileContract,
	faEarthEurope,
	faFiles,
	faBookOpenCover,
	faMoneyCheckDollarPen,
	faEnvelopesBulk,
	faPeoplePulling,
	faDiagramSubtask,
	faBooks,
} from '@fortawesome/pro-solid-svg-icons'

import AddrbookListPage from './addrbook/List'
import ContractsListPage from './contracts/List'
import ContractsCoversPage from './contracts/Covers'
import ContractsTypesListPage from './contracts/TypesList'
import ContractsAssociationsListPage from './contracts/AssociationsList'
import ZoneListPage from './zone/List'
import AssociateTypesPage from './associateType/List'
import AccountingYearsSummaryPage from './accounting/YearsSummary'
import AccountingYearsDetailsPage from './accounting/YearsDetails'
import IsmeaListPage from './ismea/List'
import IsmeaItemPage from './ismea/Edit'
import MailerListPage from './mailer/List'
import MailerRcptsListPage from './mailer/RcptsList'
import EventListPage from './events/List'
import EventParticipantListPage from './events/ParticipantList'
import WorkflowsPage from './workflows/List'
import DocumentsPage from './documents/List'

const Router = () => (
	<Routes>
		<Route path="/addrbook/:type" element={<AddrbookListPage />} />
		
		<Route path="/contracts" element={<ContractsListPage />} />
		<Route path="/contracts/covers" element={<ContractsCoversPage />} />
		<Route path="/contracts/types" element={<ContractsTypesListPage />} />
		<Route path="/contracts/associations" element={<ContractsAssociationsListPage />} />
		
		<Route path="/accounting/summary/:customer" element={<AccountingYearsSummaryPage />} />
		<Route path="/accounting/details/:customer/:year" element={<AccountingYearsDetailsPage />} />

		<Route path="/zone/list" element={<ZoneListPage />} />
		<Route path="/associate-types/list" element={<AssociateTypesPage />} />

		<Route path="/ismea/list" element={<IsmeaListPage />} />
		<Route path="/ismea/item/:_id" element={<IsmeaItemPage />} />
		<Route path="/ismea/item" element={<IsmeaItemPage />} />
		
		<Route path="/event/list" element={<EventListPage />} />
		<Route path="/event/participant/:eventId/list" element={<EventParticipantListPage />} />

		<Route path="/mailer/list" element={<MailerListPage />} />
		<Route path="/mailer/rcpts/:_id" element={<MailerRcptsListPage />} />

		<Route path="/workflows" element={<WorkflowsPage />} />

		<Route path="/documents" element={<DocumentsPage />} />

		<Route path="/" element={<Blank />} />
	</Routes>
)

const Blank = () => (
	<Tpl title="Unione Provinciale Agricoltori Brescia" />
)

const useMenu = (api) => useMemo(() => {
	const menu = []

	if(api.auth.chk('addrbook'))
		menu.push([
			{ label:"Clienti", icon:faFarm, linkTo:"/addrbook/customers" },
		])
	if(api.auth.chk('contracts'))
		menu.push([
			{ label:"Contratti", icon:faFileContract, linkTo:"/contracts" },
			{ label:"Stampa copertine", icon:faBookOpenCover, linkTo:"/contracts/covers" },
			{ label:"Tipologie", icon:faFiles, linkTo:"/contracts/types" },
			{ label:"Associazioni", icon:faBuildingWheat, linkTo:"/contracts/associations" },
		])

	if(api.auth.chkAny(['mailer-contrib', 'mailer-deleghe', 'events-org', 'mailer-contabili', 'mailer-custom']))
		menu.push([
			{ label:"Servizio mailing", icon:faEnvelopesBulk, linkTo:"/mailer/list" },
		])

	if(api.auth.chk('ismea-user'))
		menu.push([
			{ label:"Bando ISMEA", icon:faMoneyCheckDollarPen, linkTo:"/ismea/list" },
		])

	if(api.auth.chk('events-org'))
		menu.push([
			{ label:"Eventi", icon:faPeoplePulling, linkTo:"/event/list" },
		])
	
	if(api.auth.chk('workflows'))
		menu.push([
			{ label:"Flussi di lavoro", icon:faDiagramSubtask, linkTo:"/workflows" },
		])

	if(api.auth.chk())
		menu.push([
			{ label:"Archivio documenti", icon:faBooks, linkTo:"/documents" },
			{ label:"Zone", icon:faEarthEurope, linkTo:"/zone/list" },
			{ label:"Tipi associato", icon:faPeopleArrows, linkTo:"/associate-types/list" },
		])
	
	return menu
}, [ api ])

export default function App() {
	const api = useApi()
	const menu = useMenu(api)

	return (
		<APICtx.Provider value={api}>
			<Screen toolAuth menu={menu}>
				{api.chkAuth() ? <Router /> : <Blank />}
			</Screen>
		</APICtx.Provider>
	)
}
