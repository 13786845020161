import React, { useState, useContext, useEffect, useMemo } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import SpeedDial from '#SpeedDial'
import DataList from '#DataList'
import Dialog from '#Dialog'
import { faEdit, faTrash, faMagnifyingGlass, faDownload, faInboxOut, faUsersGear, faPaperPlane, faPrint, faFire } from '@fortawesome/pro-regular-svg-icons'

import EditContrib2024 from './EditContrib2024'
import EditContribPTA2024 from './EditContribPTA2024'
import EditContrib from './EditContrib'
import EditDeleghe from './EditDeleghe'
import EditContabili from './EditContabili'
import EditCustom from './EditCustom'
import { campaigns, campaignStatuses, contribTypes, delegheTypes, evalEditable, evalEvaluable, evalPaperBuildable, evalSendable } from './def'
import { Typography } from '@mui/material';

const CreateCampaign = ({ tag, handleClose, handleRefresh }) => {
	const campaignDef = useMemo(() =>
		campaigns.find(item => item.tag === tag)
	, [ tag ])

	const triggerClose = () => {
		handleClose()
		handleRefresh()
	}

	return (
		<Dialog
			handleClose={triggerClose}
			open={Boolean(campaignDef?.isCreatable)}
			title={"Creazione campagna: " + (campaignDef?.label || '')}
			width="md"
		>
			{ campaignDef?.tag === 'contrib2024' && <EditContrib2024 /> }
			{ campaignDef?.tag === 'contrib-pta-2024' && <EditContribPTA2024 /> }
			{ campaignDef?.tag === 'contrib' && <EditContrib /> }
			{ campaignDef?.tag === 'deleghe' && <EditDeleghe /> }
			{ campaignDef?.tag === 'contabili' && <EditContabili /> }
			{ campaignDef?.tag === 'custom' && <EditCustom /> }
		</Dialog>
	)
}
const EditCampaign = ({ row, handleClose }) => {
	const campaignDef = useMemo(() =>
		row?.type && campaigns.find(item => item.tag === row.type)
	, [ row ])

	return (
		<Dialog
			handleClose={handleClose}
			open={Boolean(campaignDef?.isEditable)}
			title={"Modifica campagna: " + (campaignDef?.label || '')}
			width="md"
		>
			{ campaignDef?.tag === 'contrib2024' && <EditContrib2024 _id={row._id} /> }
			{ campaignDef?.tag === 'contrib-pta-2024' && <EditContribPTA2024 _id={row._id} /> }
			{ campaignDef?.tag === 'contrib' && <EditContrib _id={row._id} /> }
			{ campaignDef?.tag === 'deleghe' && <EditDeleghe _id={row._id} /> }
			{ campaignDef?.tag === 'contabili' && <EditContabili _id={row._id} /> }
			{ campaignDef?.tag === 'custom' && <EditCustom _id={row._id} /> }
		</Dialog>
	)
}

const CaimpaignType = ({ type, delegheType, contribType, mailSubject, mailerTag }) => {
	const result = []

	let label = campaigns.find(scanItem => type === scanItem.tag )?.label || '???'
	if(type === 'deleghe')
		label += ': ' + delegheTypes.find(row => row.value === delegheType)?.label
	if(type === 'contrib')
		label += ': ' + contribTypes.find(row => row.value === contribType)?.label
	if(mailerTag)
		label += ' - ' + mailerTag.toUpperCase()
	result.push(
		<Typography>{label}</Typography>
	)

	if(mailSubject)
		result.push(
			<Typography variant='caption'>{mailSubject}</Typography>
		)

	return result
}

export default function MailerList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ createCampaign, setCreateCampaign ] = useState()
	const [ editCampaign, setEditCampaign ] = useState()
	
	const handleRefresh = () => api.call('mailer/list').then(setIds)
	const handleEvaluate = ({ _id, _registry, handleRowRefresh }) => {
		api.dialogConfirm({
			text: 'Generare la lista destinatari per la campagna "' + _registry?.number + '"?',
			onConfirm: () => api.call('mailer/evaluate', { _id }).then(handleRowRefresh),
		})
	}
	const handleSend = ({ _id, _registry, handleRowRefresh }) => {
		api.dialogConfirm({
			text: 'Iniziare l\'invio per la campagna "' + _registry?.number + '"?',
			onConfirm: () => api.call('mailer/send', { _id }).then(handleRowRefresh),
		})
	}
	const handlePaperBuild = ({ _id, _registry, handleRowRefresh }) => {
		api.dialogConfirm({
			text: 'Generare l\'invio cartaceo per la campagna "' + _registry?.number + '"?',
			onConfirm: () => api.call('mailer/build-paper', { _id }).then(handleRowRefresh),
		})
	}
	const handleDropSend = ({ _id, _registry }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler interrompere l\'invio ai destinatari rimanenti per la campagna "' + _registry?.number + '"?',
			onConfirm: () => api.call('mailer/send-drop', { _id }).then(handleRefresh),
		})
	}
	const handleDelete = ({ _id, _registry }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare la campagna "' + _registry?.number + '"?',
			onConfirm: () => api.call('mailer/del', { _id }).then(handleRefresh),
		})
	}
	useEffect(() => {
		handleRefresh()
	}, [])

	const tabDef = [
		{ label:"Numero", content:row => row?._registry?.number },
		{ label:"Data creazione", field:'_created', type:'datetime' },
		{ label:"Tipologia", content:row => <CaimpaignType {...row} /> },
		{ content:async row => {
			if(row.zone) {
				const grp = await api.auth.appGroupGet(row.zone)
				return grp?.displayName || '-'
			}
			return '-'
		}},
		{ label:"Stato", content:row => campaignStatuses.find(scanItem => row.status === scanItem.value )?.label || '-' },
	]

	const getRow = _id => api.call('mailer/get', { _id })

	const actions = []
	campaigns
		.filter(campaign => campaign.isCreatable && api.auth.chk(campaign.acl))
		.forEach(campaign => actions.push({
			tooltip: campaign.label,
			icon: campaign.icon,
			onClick: () => setCreateCampaign(campaign.tag),
		}))

	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica',
			onClick: setEditCampaign,
			show: evalEditable,
		},
		{
			icon: faMagnifyingGlass,
			label: 'Anteprima allegato',
			onClick: row => api.storage.download(row.pdfJwt, true),
			show: row => Boolean(row.pdfJwt),
		},
		{
			icon: faDownload,
			label: 'Scarica allegato',
			onClick: row => api.storage.download(row.pdfJwt, false),
			show: row => Boolean(row.pdfJwt),
		},
		{
			icon: faUsersGear,
			label: 'Genera destinatari',
			onClick: handleEvaluate,
			show: evalEvaluable,
		},
		{
			icon: faPaperPlane,
			label: 'Inizia invio',
			onClick: handleSend,
			show: evalSendable,
		},
		{
			icon: faInboxOut,
			label: 'Lista destinatari',
			linkTo: row => '/mailer/rcpts/' + row._id,
			show: row => campaignStatuses.find(scanItem => row.status === scanItem.value )?.showRcpts,
		},
		{
			icon: faPrint,
			label: 'Genera invio cartaceo',
			onClick: handlePaperBuild,
			show: evalPaperBuildable,
		},
	]
	api.auth.chk('mailer-admin') && defBtns.push(
		{
			icon: faFire,
			label: 'Manda in errore gli invii rimanenti',
			onClick: handleDropSend,
			show: row => row.status === 'sending'
		},
		{
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
		},
	)

	return (
		<Tpl title="Lista campagne d'invio">
			<CreateCampaign tag={createCampaign} handleClose={() => setCreateCampaign(null)} handleRefresh={handleRefresh} />
			<EditCampaign row={editCampaign} handleClose={() => setEditCampaign(null)} />
			<DataList def={tabDef} ids={ids} getRow={getRow} rowActions={defBtns} pageSize={30} />
			{ Boolean(actions.length) && <SpeedDial actions={actions} /> }
		</Tpl>
	)
}
