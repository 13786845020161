import { faCalendar, faCalendarImage, faHouse, faMoneyCheckDollar, faUsers } from '@fortawesome/pro-regular-svg-icons'
import { faCheck, faHourglass, faLock, faTriangleExclamation, faQrcode, faMoneyBillTrendUp } from '@fortawesome/pro-regular-svg-icons'

export const mailersOpts = [
	{
		value: 'email',
		label: 'Posta ordinaria',
	},
	{
		value: 'pec',
		label: 'PEC',
	},
]

export const campaigns = [
	{
		tag: 'contrib2024',
		acl: 'mailer-contrib2024',
		label: 'Contributi 2024',
		icon: faCalendar,
		isCreatable: false,
		isEditable: true,
		isEvaluable: true,
		isSendable: true,
		isPaperBuildable: true,
	},
	{
		tag: 'contrib-pta-2024',
		acl: 'mailer-contrib2024',
		label: 'Contributi PTA 2024',
		icon: faCalendarImage,
		isCreatable: false,
		isEditable: true,
		isEvaluable: true,
		isSendable: true,
		isPaperBuildable: true,
	},
	{
		tag: 'contrib',
		acl: 'mailer-contrib',
		label: 'Contributi',
		icon: faCalendarImage,
		isCreatable: true,
		isEditable: true,
		isEvaluable: true,
		isSendable: true,
		isPaperBuildable: true,
	},
	{
		tag: 'imu',
		acl: 'mailer-imu',
		label: 'IMU',
		icon: faHouse,
		isCreatable: false,
		isEditable: false,
		isEvaluable: false,
		isSendable: false,
		isPaperBuildable: false,
	},
	{
		tag: 'imu-cartaceo',
		acl: 'mailer-imu',
		label: 'IMU cartaceo',
		icon: faHouse,
		isCreatable: false,
		isEditable: false,
		isEvaluable: true,
		isSendable: true,
		isPaperBuildable: false,
	},
	{
		tag: 'imu-telematico',
		acl: 'mailer-imu',
		label: 'IMU telematico',
		icon: faHouse,
		isCreatable: false,
		isEditable: false,
		isEvaluable: true,
		isSendable: true,
		isPaperBuildable: false,
	},
	{
		tag: 'deleghe',
		acl: 'mailer-deleghe',
		label: 'Deleghe',
		icon: faMoneyCheckDollar,
		isCreatable: true,
		isEditable: true,
		isEvaluable: true,
		isSendable: true,
		isPaperBuildable: false,
	},
	{
		tag: 'event-qr',
		acl: 'events-org',
		label: 'Invio QR evento',
		icon: faQrcode,
		isCreatable: false,
		isEditable: false,
		isEvaluable: true,
		isSendable: true,
		isPaperBuildable: false,
	},
	{
		tag: 'contabili',
		acl: 'mailer-contabili',
		label: 'Invio schede contabili',
		icon: faMoneyBillTrendUp,
		isCreatable: true,
		isEditable: true,
		isEvaluable: true,
		isSendable: false,
		isPaperBuildable: false,
	},
	{
		tag: 'custom',
		acl: 'mailer-custom',
		label: 'Lista manuale',
		icon: faUsers,
		isCreatable: true,
		isEditable: true,
		isEvaluable: true,
		isSendable: true,
		isPaperBuildable: false,
	},
]

export const campaignStatuses = [
	{
		value: "new",
		label: "Nuovo",
		showRcpts: false,
		isEditable: true,
		isEvaluable: true,
		isSendable: false,
		isPaperBuildable: false,
	},
	{
		value: "eval-rcpts",
		label: "Elaborazione destinatari",
		showRcpts: true,
		isEditable: false,
		isEvaluable: false,
		isSendable: false,
		isPaperBuildable: false,
	},
	{
		value: "ready",
		label: "Pronto per l'invio",
		showRcpts: true,
		isEditable: true,
		isEvaluable: true,
		isSendable: true,
		isPaperBuildable: false,
	},
	{
		value: "sending",
		label: "Invio in corso",
		showRcpts: true,
		isEditable: false,
		isEvaluable: false,
		isSendable: false,
		isPaperBuildable: false,
	},
	{
		value: "done",
		label: "Invio completato",
		showRcpts: true,
		isEditable: false,
		isEvaluable: true,
		isSendable: false,
		isPaperBuildable: true,
	},
	{
		value: "paper-building",
		label: "Preparazione cartaceo",
		showRcpts: false,
		isEditable: false,
		isEvaluable: false,
		isSendable: false,
		isPaperBuildable: false,
	},
]
export const rcptStatuses = [
	{
		value: "todo",
		label: "Da inviare",
		icon: faHourglass,
		allowLock: true,
	},
	{
		value: "sent",
		label: "Inviata",
		icon: faCheck,
		allowLock: false,
	},
	{
		value: "disabled",
		label:"Disattivato",
		icon: faLock,
		allowLock: false,
	},
	{
		value: "error",
		label: "Errore",
		icon: faTriangleExclamation,
		allowLock: true,
	},
]

export const delegheTypes = [
	{ value:'imu-telematico', label:'IMU - telematico' },
	{ value:'imu-cartaceo', label:'IMU - cartaceo' },
	{ value:'irpef-telematico', label:'IRPEF - telematico' },
	{ value:'irpef-cartaceo', label:'IRPEF - cartaceo' },
	{ value:'contributi-cdia-telematico', label:'Contributi CD/IA - telematico' },
	{ value:'contributi-cdia-cartaceo', label:'Contributi CD/IA - cartaceo' },
]

export const contribTypes = [
	{ value:'associates-2025', label:'Soci ordinari - 2025' },
	{ value:'pta-2025', label:'PTA - 2025' },
]

export const evalEditable = ({ type, status }) => {
	const evalCampaign = campaigns.find(scanItem => type === scanItem.tag )?.isEditable
	const evalStatus = campaignStatuses.find(scanItem => status === scanItem.value )?.isEditable
	return evalCampaign && evalStatus
}

export const evalEvaluable = ({ type, status }) => {
	const evalCampaign = campaigns.find(scanItem => type === scanItem.tag )?.isEvaluable
	const evalStatus = campaignStatuses.find(scanItem => status === scanItem.value )?.isEvaluable
	return evalCampaign && evalStatus
}

export const evalSendable = ({ type, status }) => {
	const evalCampaign = campaigns.find(scanItem => type === scanItem.tag )?.isSendable
	const evalStatus = campaignStatuses.find(scanItem => status === scanItem.value )?.isSendable
	return evalCampaign && evalStatus
}

export const evalPaperBuildable = ({ type, status, paper }) => {
	const evalCampaign = campaigns.find(scanItem => type === scanItem.tag )?.isPaperBuildable
	const evalStatus = campaignStatuses.find(scanItem => status === scanItem.value )?.isPaperBuildable
	return evalCampaign && evalStatus
}
