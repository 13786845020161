import React from 'react';

import Form, { FileUpload, Select } from '#Form'
import { contribTypes } from './def';

export default function CampaignEdit({ _id }) {
	const formDef = {
		_id,
		callSet: _id ? "mailer/contrib/update" : "mailer/contrib/create",
		callGet: _id && "mailer/contrib/get",
		// extraData: { type:'contrib' },
	}

	const isRO = Boolean(_id)

	return (
		<Form {...formDef}>
			<Select name="contribType" label="Tipologia contribuenti" options={contribTypes} emptyLabel="-- seleziona una tipologia --" disabled={isRO} />
			<FileUpload label="Allegato aggiuntivo" name="attachment" tokenEndpoint="mailer/get-upload-token" />
		</Form>
	)
}
