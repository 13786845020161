export const eventTypes = [
	{ value:'assemblea', label:'Assemblea'}
]

export const participantStates = [
	{ value:'active', label:'Attivi (nuovi e approvati)' },
	{ value:'new', label:'Nuovo' },
	{ value:'approved', label:'Approvato' },
	{ value:'refused', label:'Rifiutato' },
]

export const referenceOptions = [
	{ value:'brescia', label:'BRESCIA' },
	{ value:'leno', label:'LENO' },
	{ value:'montichiari', label:'MONTICHIARI' },
	{ value:'verolanuova', label:'VEROLANUOVA' },
	{ value:'chiari', label:'CHIARI' },
	{ value:'orzinuovi', label:'ORZINUOVI' },
	{ value:'lonato', label:'LONATO' },
	{ value:'darfo', label:'DARFO' },
	{ value:'altro', label:'ALTRO' },
	{ value:'autorità', label:'Autorità' },
	{ value:'sponsor', label:'Sponsor' },
	{ value:'dipendente', label:'Dipendente' },
	{ value:'stampa', label:'Stampa' },
	{ value:'consiglio', label:'Consiglio' },
	{ value:'associazione', label:'Associazione' },
	{ value:'altre-confagri', label:'Altre Confagricoltura' },
]
export const attendOptions = [
	{ value:'assembly', label: 'ASSEMBLEA' },
	{ value:'all', label: 'ASSEMBLEA + CENA' },
]
export const menuOptions = [
	{ value:'meat', label: 'SPIEDO' },
	{ value:'veg', label: 'PIATTO VEGETARIANO' },
]
export const scanOptions = [
	{ value:'done', label: 'Scansionato' },
	{ value:'missing', label: 'Non scansionato' },
]
