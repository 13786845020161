import React, { useEffect, useState, useContext } from 'react';

import APICtx from '#api'
import Form, { FileUpload, Select, Input } from '#Form'
import { mailersOpts } from './def';

export default function CampaignEdit({ _id }) {
	const api = useContext(APICtx)
	const [ data, setData ] = useState({})
	const [ zones, setZones ] = useState([])

	useEffect(() => {
		api.auth.groupsAsOptions({ acl:'mailer-deleghe-zone' }).then(opts => {
			setZones(opts.filter(opt => api.auth.groups.includes(opt.address)))
		})
	}, [])

	const formDef = {
		_id,
		callSet: _id ? "mailer/custom/update" : "mailer/custom/create",
		callGet: _id && "mailer/custom/get",
		// extraData: { type:'contrib' },
		data, setData,
	}

	const isRO = Boolean(_id)

	return (
		<Form {...formDef}>
			<Select name="zone" label="Zona assegnata" options={zones} emptyLabel="-- seleziona una zona --" disabled={isRO} />
			<Select name="mailerTag" label="Canale d'invio" options={mailersOpts} emptyLabel="-- seleziona un canale d'invio --" />
			<Input name="mailSubject" label="Email - oggetto" />
			<Input name="mailContent" label="Email - testo" multiline />
			<FileUpload label="Allegato" name="attachment" tokenEndpoint="mailer/get-upload-token" />
			<Input name="recipients" label="Elenco destinatari, uno per riga" multiline />
		</Form>
	)
}
